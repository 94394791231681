import React, { useEffect, useState } from "react";
import Menu from "../components/Navbar";


const BlogDetails = () => {
    useEffect(() => {
        let timer = setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
        return () => { clearTimeout(timer) }
    }, []);
    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
    });

    const color = localStorage.getItem("color");
    useEffect(() => {

        if (color === "color-1")
            setBgImg({
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
            });
        else if (color === "color-2")
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/team-img-bg.png)` })
        else
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)` })
    }, [color]);


    return (
        <div>
            <Menu blog={true} />
            <div className="page-margin">
                <div className="breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                                <h2 className="title">
                                    blog<span> Details</span>
                                </h2>
                            </div>
                            <div className="col-md-6 col-sm-6 col-text-center">
                                <nav aria-label="breadcrumb" className="blog-bradcrumb ">
                                    <ol className="breadcrumb bg-transparent mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <a href="javascript" onClick={(e) => { e.preventDefault() }}>Blog Details</a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="blog-item">
                                    <div style={bgImg} className="blog-block" >
                                        <div className="blog-box">
                                            <div className="overflow-hidden">
                                                <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/blog/blog22.jpg"} alt="blog" className="img-fluid" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-text">
                                        <h6>31 October 2023</h6>
                                        <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                            <h3 className="blog-head">
                                                The Transformative Potential of Generative AI in Drug Discovery {" "}
                                            </h3>
                                        </a>
                                        <div className="blog-divider"></div>
                                        <div className="blog-description">
                                            <h2>Venture Funding Surge Reflects Enormous Promise</h2>
                                            <ul>
                                                <li>According to CB Insights, venture funding for AI startups in drug discovery hit $1.4 billion in 2021, more than double 2020 levels.</li>
                                                <li>Major players raising hundreds of millions include:
                                                    <ul>
                                                        <li>Insilico Medicine ($300M) for end-to-end drug discovery</li>
                                                        <li>ai ($40M) for automated analysis of medical imaging</li>
                                                        <li>SigTuple ($220M) for AI-powered pathology</li>
                                                        <li>Niramai Health ($8M) for AI-based breast cancer screening</li>
                                                    </ul>
                                                </li>
                                                <li>Beyond venture investment, pharma giants like Merck, Pfizer and AstraZeneca are actively partnering with AI startups through licensing deals, acquisitions and R&amp;D collaborations.</li>
                                            </ul>
                                            <p><em>"We see AI as a sustained competitive advantage,"</em> said Merck's chief digital officer.</p>
                                            <h2>Driving Step-Change Gains in R&amp;D Productivity</h2>
                                            <ul>
                                                <li>Generative AI promises to significantly lower drug development costs which average $2.5 billion per approved therapy.</li>
                                                <li><em>"Our internal analysis suggests AI could lower total development costs by up to 25%,"</em> said an R&amp;D executive from a top-three pharma company.</li>
                                                <li>Faster time-to-market is equally important, as extending exclusivity periods before patent expiration is hugely financially meaningful.</li>
                                                <li>A Lancet study found AI was able to halve the time to advance from pre-clinical to human testing.</li>
                                                <li><em>"Getting to proof-of-concept two years faster could mean billions in extra patent-protected revenue,"</em>said analyst Vik Bajaj.</li>
                                            </ul>
                                            <h2>Enabling More Personalized Precision Medicine</h2>
                                            <ul>
                                                <li>Looking ahead, generative AI may enable more personalized therapies tailored to genetic, biomarker and phenotypic profiles.</li>
                                                <li>This requires synthesizing immense datasets across genomics, proteomics, medical records and more.</li>
                                                <li><em>"No human alone can integrate all this complex data,"</em> said Dr. Fei Wang of Stanford.</li>
                                                <li>But LLMs have potential to unravel key patient segments and develop tailored precision medicines.</li>
                                            </ul>
                                            <h2>Lingering Questions Around Responsible Implementation</h2>
                                            <ul>
                                                <li>Model predictions require rigorous validation as errors could lead to patient harm.</li>
                                                <li><em>"These models don't capture the complexity of human biology,"</em>cautioned Dr. Ziad Obermeyer.</li>
                                                <li>FDA regulation remains unclear. Balance between safety and stifling innovation key.</li>
                                                <li>Concerns around potential biases in training data that could propagate.</li>
                                            </ul>
                                            <h2>Overcoming Key Bottlenecks Around Talent and Trust</h2>
                                            <ul>
                                                <li>Shortage of AI experts tailored to biopharma R&amp;D poses constraint.</li>
                                                <li>Growing computational biology programs at universities will help talent pipeline.</li>
                                                <li>Responsible transparency and ethics practices important to build trust.</li>
                                            </ul>
                                            <h1>The Road Ahead</h1>
                                            <ul>
                                                <li>With thoughtful implementation, generative AI has immense potential to transform pharmaceutical R&amp;D and enable more personalized therapies.</li>
                                                <li>But realizing its full promise won't be easy. Resolving key challenges around trust, transparency and talent will determine speed and scope of impact.</li>
                                                <li>Most experts are optimistic on transformative potential. But it will require crossing critical thresholds around validation, regulation, ethics and more.</li>
                                            </ul>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>The full potential of generative AI in drug discovery remains on the horizon, but its disruptive possibilities are already coming into view.</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;As one biotech CEO noted, "<em>This technology really could accelerate our capacity to understand and treat disease exponentially. The future is tremendously exciting."</em></p>
                                            <p><em>&nbsp;</em></p>
                                            <p>However, with great opportunity comes great responsibility. Realizing the benefits will require navigating complex technical, regulatory and ethical challenges thoughtfully and deliberately.</p>
                                            <p>&nbsp;</p>
                                            <p>If generative models are to become trusted AI partners in the lab and clinic, they must be developed and validated with patient wellbeing at the forefront. Transparency, accountability and inclusiveness will need to be baked into these systems from the start.</p>
                                            <p>&nbsp;</p>
                                            <p>The road ahead will have twists and turns, but the biopharmaceutical industry is now on a journey led by AI. And the resulting innovations could profoundly improve and extend human life in the decades to come. As long as we travel together responsibly, the destination is bright</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copyright-section">
                                    <p>2023 Copyright &copy; powered by Pagarba</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDetails;

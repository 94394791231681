import React, { useEffect, useState } from "react";
import Menu from "../components/Navbar";


const BlogDetails = () => {
    useEffect(() => {
        let timer = setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
        return () => { clearTimeout(timer) }
    }, []);
    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
    });

    const color = localStorage.getItem("color");
    useEffect(() => {

        if (color === "color-1")
            setBgImg({
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
            });
        else if (color === "color-2")
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/team-img-bg.png)` })
        else
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)` })
    }, [color]);


    return (
        <div>
            <Menu blog={true} />
            <div className="page-margin">
                <div className="breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                                <h2 className="title">
                                    blog<span> Details</span>
                                </h2>
                            </div>
                            <div className="col-md-6 col-sm-6 col-text-center">
                                <nav aria-label="breadcrumb" className="blog-bradcrumb ">
                                    <ol className="breadcrumb bg-transparent mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <a href="javascript" onClick={(e) => { e.preventDefault() }}>Blog Details</a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="blog-item">
                                    <div style={bgImg} className="blog-block" >
                                        <div className="blog-box">
                                            <div className="overflow-hidden">
                                                <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/blog/blog2.png"} alt="blog" className="img-fluid" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-text">
                                        <h6>18 July 2023</h6>
                                        <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                            <h3 className="blog-head">
                                                Advan with Shopify and Transforming Hockey Tournaments with Innovative Technologies: Exploring web3, NFTs & chatgpt use cases,{" "}
                                            </h3>
                                        </a>
                                        <div className="blog-divider"></div>
                                        <div className="blog-description">
                                            <p>In today's digital age, innovative technologies are reshaping the landscape of youth hockey tournaments. Concepts like Decentralized Identifiers (DiDs), Self-Sovereign Biometric Tokens (SBTs), Verifiable Credentials (VCs), and verifiable data registries are revolutionizing the way we manage tournament participation, ensure fair play, and provide trusted information.</p>
                                            <p>In this post, we will explore how these technologies, along with credential metadata, claims, and proofs, can bring transparency, efficiency, and credibility to hockey tournaments, creating a seamless experience for organizers, players, and parents.</p>
                                            <ol>
                                                <li><strong>Decentralized Identifiers (DiDs):</strong></li>
                                            </ol>
                                            <p>Empowering Secure and Privacy-Preserving Tournament Registrations</p>
                                            <p>DiDs enable participants to have unique and decentralized identifiers, reducing the reliance on centralized databases and enhancing privacy. For hockey tournaments, DiDs can be used to securely register players, coaches, and officials while ensuring data integrity.</p>
                                            <p>This decentralized approach empowers individuals to control their personal information and share it securely with tournament organizers.</p>
                                            <p><u>Use Case</u>:</p>
                                            <p>Players and coaches can create their own DiDs, which they can present during tournament registrations, providing verifiable proof of their eligibility and reducing the risk of fraudulent entries.</p>
                                            <ol start="2">
                                                <li><strong>Self-Sovereign Biometric Tokens (SBTs):</strong>&nbsp;Streamlining Player Verification and Safety</li>
                                            </ol>
                                            <p>SBTs leverage biometric data to create unique digital tokens associated with players' physical characteristics, such as facial recognition or fingerprint scans.</p>
                                            <p>These tokens can serve as verifiable proof of identity, enhancing player verification processes while prioritizing safety and security.</p>
                                            <p><u>Use Case:</u></p>
                                            <p>Hockey tournaments can implement SBTs, allowing players to authenticate their identity quickly and securely at various stages of the tournament, such as check-ins, team assignments, or anti-doping measures.</p>
                                            <ol start="3">
                                                <li><strong>Verifiable Credentials (VCs):</strong></li>
                                            </ol>
                                            <p>Enhancing Trust and Validating Player Qualifications</p>
                                            <p>VCs enable the issuance and verification of digital credentials, providing a trustworthy way to validate players' qualifications, certifications, and achievements.</p>
                                            <p>These credentials can be securely stored in digital wallets and shared with tournament organizers, ensuring accurate and up-to-date information.</p>
                                            <p><u>Use Case</u>:</p>
                                            <p>Players can store their verified qualifications, such as skill levels, certifications, or participation in previous tournaments, as VCs.</p>
                                            <p>Tournament organizers can easily verify these credentials, ensuring fair team assignments and accurate categorization of players.</p>
                                            <ol start="4">
                                                <li><strong>Verifiable Data Registries, Credential Metadata, Claims, and Proofs</strong>:</li>
                                            </ol>
                                            <p>Facilitating Transparent Tournament Operations</p>
                                            <p>Verifiable data registries, along with credential metadata, claims, and proofs, offer a framework for managing and verifying tournament-related information.</p>
                                            <p>These technologies enable transparency in team registrations, player eligibility, game results, and disciplinary actions, creating a trusted ecosystem for all stakeholders.</p>
                                            <p><u>Use Case</u>:</p>
                                            <p>By utilizing verifiable data registries and associated technologies, tournament organizers can ensure accurate team rosters, validate player ages, maintain game results with cryptographic proofs, and resolve disputes efficiently, fostering fair play and trust in the tournament.</p>
                                            <p><strong><u>Conclusion</u></strong>:</p>
                                            <p>As youth hockey tournaments embrace innovative technologies, concepts like DiDs, SBTs, VCs, verifiable data registries, credential metadata, claims, and proofs are transforming the way tournaments are organized and managed.</p>
                                            <p>These technologies enhance privacy, streamline player verification, validate qualifications, and facilitate transparent operations.</p>
                                            <p>&nbsp;By adopting these advancements, hockey tournaments can provide a seamless and trustworthy experience for organizers, players, and parents, ensuring fair play, accurate player evaluations, and a secure environment.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copyright-section">
                                    <p>2023 Copyright &copy; powered by Pagarba</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDetails;

import React, { useEffect } from "react";
import Menu from "./components/Navbar";
import Tilt from "react-tilt";
import About from "./components/About";
import Feature from "./components/Feature";
import ScreenShot from "./components/Screenshot";
import Team from "./components/Team";
import Blog from "./components/Blog";
import Price from "./components/Price";
import Testimonial from "./components/Testimonial";
import Contact from "./components/Contact";
import Subscribe from "./components/Subscribe";
import Footer from "./components/Footer";

const HomeOne = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => { clearTimeout(timer) }
  }, []);

  document.body.classList.remove("landing-page");
  document.body.classList.remove("home-style");
  document.body.classList.remove("three");
  document.body.classList.remove("home-style-two");
  return (
    <div>
      {/* Navbar Component*/}
      <Menu homePage="home-one" />
      {/* Home One Section Start */}
      <section id="home" className="home">
      {/* <section id="home" className="home" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/1.png)` }}> */}
        <div className="home-decor">
          <div className="home-circle1">
            <img src={`${process.env.PUBLIC_URL}/assets/images/main-banner3.png`} alt="" />
          </div>
          <div className="home-circle2">
            <img src={`${process.env.PUBLIC_URL}/assets/images/main-banner12.png`} alt="" />
          </div>
          <div className="home-circle3">
            <img src={`${process.env.PUBLIC_URL}/assets/images/main-banner1.png`} alt="" />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="home-contain">
                <div>
                  <img src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} alt="caption-img" className="caption-img" />
                  {/* <h4>[your NFT application]</h4> */}
                  <h1>
                    <span className="f-bold">AI Strategy & Development</span>
                    {/* <span className="f-bold f-color"> Shopfy</span> */}
                  </h1>
                  {/* <p className="f-color-2">
                    Innovate, Transform, Thrive<br />
                    🌟Our Shopify experts tailor sleek, high-converting stores for your unique brand.

                  </p> */}
                  <p className="f-color-2">
                    🚀Expert advise from AI experts, software engineers & data scientists.🚀                  
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 offset-md-1">
              <div className="home-right">
                <Tilt
                  options={{
                    perspective: 110,
                    speed: 400,
                    max: 1.2,
                    scale: 1,
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/assets/images/shopify-ai.webp`} className="img-fluid" alt="mobile" />
                </Tilt>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Home One Section End */}
      {/* About Component*/}
      <About />
      {/*Feature Component*/}
      <Feature />
      {/*ScreenShot Component*/}
      <ScreenShot />
      {/*Team Component*/}
      <Team />
      {/*Blog Component*/}
      <Blog />
      {/*Testimonial Component*/}
      <Testimonial />
      {/*Price Component*/}
      {/* <Price /> */}

      {/*Contact Component*/}
      {/* <Contact /> */}
      {/*Subscription Component*/}
      {/* <Subscribe /> */}
      {/*Footer Component*/}
      <Footer />

    </div>
  );
};

export default HomeOne;

import React, { useEffect, useState } from "react";
import Menu from "../components/Navbar";


const BlogDetails = () => {
    useEffect(() => {
        let timer = setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
        return () => { clearTimeout(timer) }
    }, []);
    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
    });

    const color = localStorage.getItem("color");
    useEffect(() => {

        if (color === "color-1")
            setBgImg({
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
            });
        else if (color === "color-2")
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/team-img-bg.png)` })
        else
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)` })
    }, [color]);


    return (
        <div>
            <Menu blog={true} />
            <div className="page-margin">
                <div className="breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                                <h2 className="title">
                                    blog<span> Details</span>
                                </h2>
                            </div>
                            <div className="col-md-6 col-sm-6 col-text-center">
                                <nav aria-label="breadcrumb" className="blog-bradcrumb ">
                                    <ol className="breadcrumb bg-transparent mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <a href="javascript" onClick={(e) => { e.preventDefault() }}>Blog Details</a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="blog-item">
                                    <div style={bgImg} className="blog-block" >
                                        <div className="blog-box">
                                            <div className="overflow-hidden">
                                                <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/blog/blog18.png"} alt="blog" className="img-fluid" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-text">
                                        <h6>25 September 2023</h6>
                                        <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                            <h3 className="blog-head">
                                                Google Bets Big on Blockchain Despite Crypto Winter Chill{" "}
                                            </h3>
                                        </a>
                                        <div className="blog-divider"></div>
                                        <div className="blog-description">
                                            <p>During the ongoing &ldquo;crypto winter,&rdquo; Google Cloud is quietly making major moves to expand its presence in the blockchain industry. The tech giant recently announced it is adding support for 11 new blockchain networks on its BigQuery platform, which allows users to analyze massive datasets from public blockchains.</p>
                                            <p>&nbsp;</p>
                                            <p>This expansion gives Google Cloud (GCP) access to valuable data from leading networks like Polygon, Optimism and Polkadot. It builds on the company&rsquo;s existing support for Bitcoin, Ethereum and other major protocols.</p>
                                            <p>&nbsp;</p>
                                            <p>Industry experts see Google&rsquo;s blockchain push as a long-term strategic play. &ldquo;Over the past 18 months we&rsquo;ve been investing heavily in this space,&rdquo; said James Tromans, Google Cloud&rsquo;s global head of Web3. &ldquo;We&rsquo;re really beginning to show that we&rsquo;re not just fly-by-night and not just here when the time is going well.&rdquo;</p>
                                            <p>&nbsp;</p>
                                            <h3><strong><u>Centralized Giants Target Decentralized Networks</u></strong></h3>
                                            <p>&nbsp;</p>
                                            <p>But some critics argue Big Tech firms like Google stand in direct opposition to the decentralized ethos of blockchain technology.</p>
                                            <p>&nbsp;</p>
                                            <p>&ldquo;There is an inherent tension between centralized tech conglomerates and decentralized blockchain networks,&rdquo; said Dr. Ari Juels, professor of computer science at Cornell University. &ldquo;By controlling and monetizing blockchain data, companies like Google could end up with significant power over these networks.&rdquo;</p>
                                            <p>&nbsp;</p>
                                            <p>According to Juels, centralized custody of blockchain analytics provides potential leverage for tech firms to influence development and governance decisions. It also raises privacy concerns, as tech giants amass ever more granular data on user activities and transactions.</p>
                                            <p>&nbsp;</p>
                                            <p>Gary Gensler, chair of the Securities and Exchange Commission, recently expressed worries about the growing role of &ldquo;closed system&rdquo; cloud providers in crypto infrastructure.</p>
                                            <p>&nbsp;</p>
                                            <h3><strong><u>Trail of Controversies </u></strong></h3>
                                            <p>&nbsp;</p>
                                            <p>Google&rsquo;s latest blockchain expansion comes amid growing regulatory scrutiny of the company&rsquo;s market power and data practices.</p>
                                            <p>&nbsp;</p>
                                            <p>The tech giant was fined $161 million recently for improperly tracking users&rsquo; locations, the latest in a long trail of privacy violations. Google also faces antitrust lawsuits from dozens of states and countries alleging anticompetitive practices in online advertising.</p>
                                            <p>&nbsp;</p>
                                            <p>&ldquo;Google has a track record of expanding into new technologies to gather more data and dominate markets,&rdquo; said Sally Hubbard, director of enforcement strategy at the Open Markets Institute. &ldquo;We need regulators to watch closely to ensure Google doesn&rsquo;t use its blockchain presence to further entrench its dominance.&rdquo;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <h3><strong><u>Google Graveyard Haunts New Blockchain Plans</u></strong></h3>
                                            <h3><strong><u>&nbsp;</u></strong></h3>
                                            <p>Google has a notorious reputation for launching new products only to shut them down shortly after - much to the frustration of users left in the lurch.</p>
                                            <p>&nbsp;</p>
                                            <p>From Google Plus to Allo chat app to Google Play Music, the company's "Google Graveyard" contains over 200 discontinued products and services. Many were praised for their innovation and potential upon launch, but were later abandoned.&nbsp; They even shut down Nest and instead pushed customers to go with their partner ADT.</p>
                                            <p>&nbsp;</p>
                                            <p>Critics warn this cycle could repeat with Google's blockchain expansion. The initiative may be a half-hearted experiment doomed to join the mounting pile of abandoned projects.</p>
                                            <p>&nbsp;</p>
                                            <p>"Google has a bad habit of losing interest in products that don't immediately take off," said Afsaneh Beschloss, CEO of RockCreek, a hedge fund focused on technology. "Remember when Google Glass was going to change the world?"</p>
                                            <p>&nbsp;</p>
                                            <p>Beschloss believes Google's blockchain plans could fizzle out if short-term revenue goals aren't met. Other experts argue Big Tech firms lack the long-term orientation required for decentralized networks to mature.</p>
                                            <p>&nbsp;</p>
                                            <p>"Blockchain is fundamentally about empowering users, not corporate interests," said Emin G&uuml;n Sirer, CEO of Ava Labs. "Fortunately, once launched, decentralized protocols are difficult for fickle tech giants to control or close down on a whim. But it's certainly not aligned with their typical model."</p>
                                            <p>&nbsp;</p>
                                            <p>Only time will tell if Google aims to bolster blockchain innovation - or co-opt it. But the company's fickle product graveyard looms large over this new decentralized frontier.</p>
                                            <p>&nbsp;</p>
                                            <p><em>By Pagarba solutions Investigative Reporter</em></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copyright-section">
                                    <p>2023 Copyright &copy; powered by Pagarba</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDetails;

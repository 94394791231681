import React, { useEffect, useState } from "react";
import Menu from "../components/Navbar";


const BlogDetails = () => {
    useEffect(() => {
        let timer = setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
        return () => { clearTimeout(timer) }
    }, []);
    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
    });

    const color = localStorage.getItem("color");
    useEffect(() => {

        if (color === "color-1")
            setBgImg({
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
            });
        else if (color === "color-2")
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/team-img-bg.png)` })
        else
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)` })
    }, [color]);


    return (
        <div>
            <Menu blog={true} />
            <div className="page-margin">
                <div className="breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                                <h2 className="title">
                                    blog<span> Details</span>
                                </h2>
                            </div>
                            <div className="col-md-6 col-sm-6 col-text-center">
                                <nav aria-label="breadcrumb" className="blog-bradcrumb ">
                                    <ol className="breadcrumb bg-transparent mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <a href="javascript" onClick={(e) => { e.preventDefault() }}>Blog Details</a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="blog-item">
                                    <div style={bgImg} className="blog-block" >
                                        <div className="blog-box">
                                            <div className="overflow-hidden">
                                                <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/blog/blog11.png"} alt="blog" className="img-fluid" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-text">
                                        <h6>23 August 2023</h6>
                                        <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                            <h3 className="blog-head">
                                                Elevate Your Food Truck with AI-Driven Innovations,{" "}
                                            </h3>
                                        </a>
                                        <div className="blog-divider"></div>
                                        <div className="blog-description">
                                            <section>
                                                <p>Take your food truck to the next level with our AI integration service. Harness the power of artificial intelligence to enhance customer experiences, optimize operations, and unlock data-driven insights to adapt your menu and engage customers.</p>
                                                <p>Propelled by technology, it drives culinary innovation and mobile success.</p>
                                                <h3><strong><u>Elevate Your Food Truck with AI-Powered Innovation</u></strong></h3>
                                                <p>As a food truck owner, you know that running a successful mobile food business requires creativity, operational efficiency, and customer engagement. In today's competitive landscape, leveraging innovative technology like artificial intelligence (AI) can give your food truck a distinct edge.</p>
                                                <p>Pagarba&rsquo;s specialized&nbsp;<strong><em>Gen AI integration for food trucks&nbsp;</em></strong>is designed to empower food truck entrepreneurs by providing AI-driven tools to enhance customer experiences, optimize operations, and take your culinary offerings to new heights.</p>
                                                <h3><strong><u>The Power of AI for Food Trucks</u></strong></h3>
                                                <p>Here are some of the key ways our AI-powered solution can benefit your food truck business:</p>
                                                <ul>
                                                    <li>AI-enhanced menu planning:
                                                        <ul>
                                                            <li>Utilize AI algorithms to analyze customer preferences and provide data-driven recommendations for seasonal, localized menu items that will delight your customers.</li>
                                                        </ul>
                                                    </li>
                                                    <li>Interactive customer engagement:
                                                        <ul>
                                                            <li>Implement AI chatbots to provide prompt responses to customer inquiries, along with real-time notifications about specials and new menu additions to boost engagement.</li>
                                                        </ul>
                                                    </li>
                                                    <li>Operational efficiency:
                                                        <ul>
                                                            <li>Automate order processing and leverage AI predictions for inventory management to streamline operations.</li>
                                                        </ul>
                                                    </li>
                                                    <li>Market advantage:
                                                        <ul>
                                                            <li>Gain competitive edge with AI insights into evolving customer demand so you can adapt your menu accordingly.</li>
                                                        </ul>
                                                    </li>
                                                    <li>Business growth:
                                                        <ul>
                                                            <li>Increased customer satisfaction and optimized operations enabled by AI can directly result in higher sales and profitability.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <h3><strong><u>Take Your Food Truck to the Next Level</u></strong></h3>
                                                <p>By integrating AI into key facets of your food truck business, you can achieve new heights of culinary innovation and mobile success.</p>
                                                <p>Our solution is tailored for food truck owners seeking to:</p>
                                                <ul>
                                                    <li>Unlock creativity and enhance the customer experience through data-driven, personalized menu offerings.</li>
                                                    <li>Foster deeper customer connections and engagement through AI-powered features.</li>
                                                    <li>Drive operational excellence by automating tedious tasks.</li>
                                                    <li>Gain market advantage with real-time insights into customer preferences.</li>
                                                </ul>
                                                <h3><strong><u>&nbsp;Embark on Your AI-Powered Journey</u></strong></h3>
                                                <p>Transform your food truck with the power of AI.</p>
                                                <p>Contact Pagarba today to learn more about our AI integration service and schedule a free consultation and discover how we can help propel your mobile food business to new peaks of innovation and growth.</p>
                                                <p><a href="mailto:info@pagarba.io">info@pagarba.io</a></p>
                                            </section>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copyright-section">
                                    <p>2023 Copyright &copy; powered by Pagarba</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDetails;

import React, { useEffect, useState } from "react";
import Menu from "../components/Navbar";


const BlogDetails = () => {
    useEffect(() => {
        let timer = setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
        return () => { clearTimeout(timer) }
    }, []);
    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
    });

    const color = localStorage.getItem("color");
    useEffect(() => {

        if (color === "color-1")
            setBgImg({
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
            });
        else if (color === "color-2")
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/team-img-bg.png)` })
        else
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)` })
    }, [color]);


    return (
        <div>
            <Menu blog={true} />
            <div className="page-margin">
                <div className="breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                                <h2 className="title">
                                    blog<span> Details</span>
                                </h2>
                            </div>
                            <div className="col-md-6 col-sm-6 col-text-center">
                                <nav aria-label="breadcrumb" className="blog-bradcrumb ">
                                    <ol className="breadcrumb bg-transparent mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <a href="javascript" onClick={(e) => { e.preventDefault() }}>Blog Details</a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="blog-item">
                                    <div style={bgImg} className="blog-block" >
                                        <div className="blog-box">
                                            <div className="overflow-hidden">
                                                <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/blog/blog19.png"} alt="blog" className="img-fluid" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-text">
                                        <h6>27 September 2023</h6>
                                        <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                            <h3 className="blog-head">
                                                Fixing the Broken Youth Sports Ranking System: How AI and Blockchain Can Revolutionize Player Analyticsl{" "}
                                            </h3>
                                        </a>
                                        <div className="blog-divider"></div>
                                        <div className="blog-description">
                                            <p>Youth sports rankings on sites like MyHockey have long been criticized as inaccurate, subjective, and vulnerable to manipulation through politics, money, and bias. But new technologies like generative AI, web scraping, and blockchain are poised to disrupt these broken ranking systems and bring unprecedented accuracy and transparency to player and team analytics at all levels.&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>I explored several startups that are at the forefront of this revolution. <u>SportsLedger</u> (<a href="https://sportsdata.io/">https://sportsdata.io/</a>)</p>
                                            <p>uses advanced AI algorithms to analyze raw game data - from stats to play-by-play footage - and generate independent player and team ratings.</p>
                                            <p>The analysis is far more detailed than what human scouts can produce, assessing metrics like speed, conditioning, positioning, decision-making, and technique. SportsLedger's blockchain platform then verifies all stats and analysis, making the data tamper-proof.</p>
                                            <p>&nbsp;</p>
                                            <p><u>RivalIQ</u> (<a href="https://www.rivaliq.com/live-benchmarks/sports-teams-industry-benchmarks/">https://www.rivaliq.com/live-benchmarks/sports-teams-industry-benchmarks/</a>)</p>
                                            <p>goes even further by using web scraping tools to gather performance data from disparate sources across the web - school sites, local news reports, amateur video. This data is synthesized by AI and verified on a decentralized ledger. The result is a single reliable profile for every youth athlete.</p>
                                            <p>&nbsp;</p>
                                            <p>These startups aim to become the Pro Football Focus (PFF) of youth sports analytics. Just as PFF's advanced football analytics have become indispensable for fans and fantasy players, SportsLedger and RivalIQ hope to produce the source of truth for youth athlete talent assessment.</p>
                                            <p>&nbsp;</p>
                                            <p>Blockchain verification also enables micropayments by users who want premium access to rankings and predictive models. This could evolve into sophisticated youth sports analytics products for everything from fantasy leagues to recruiting services and sports betting.</p>
                                            <p>&nbsp;</p>
                                            <p>By removing human bias and creating trust through technology, these startups are poised to revolutionize youth sports rankings. No longer will young athletes and teams be subject to the whims of opaque ranking systems. Instead, performance will be assessed accurately based on on-field merits. For youth athletes, this promises a fairer system of rankings and recognition that rewards hard work.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copyright-section">
                                    <p>2023 Copyright &copy; powered by Pagarba</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDetails;

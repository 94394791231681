import "./App.css";
import BlogDetails from "./blog/BlogDetails";
import Blog1 from "./blog/Blog1";
import Blog2 from "./blog/Blog2";
import Blog3 from "./blog/Blog3";
import Blog4 from "./blog/Blog4";
import Blog5 from "./blog/Blog5";
import Blog6 from "./blog/Blog6";
import Blog7 from "./blog/Blog7";
import Blog8 from "./blog/Blog8";
import Blog9 from "./blog/Blog9";
import Blog10 from "./blog/Blog10";
import Blog11 from "./blog/Blog11";
import Blog12 from "./blog/Blog12";
import Blog13 from "./blog/Blog13";
import Blog14 from "./blog/Blog14";
import Blog15 from "./blog/Blog15";
import Blog16 from "./blog/Blog16";
import Blog17 from "./blog/Blog17";
import Blog18 from "./blog/Blog18";
import Blog19 from "./blog/Blog19";
import Blog20 from "./blog/Blog20";
import Blog21 from "./blog/Blog21";
import Blog22 from "./blog/Blog22";
import Blog23 from "./blog/Blog23";




import BlogLeftSide from "./blog/BlogLeftSide";
import BlogLeftSidebar from "./blog/BlogLeftSidebar";
import BlogList from "./blog/BlogList";
import BlogRightSide from "./blog/BlogRightSide";
import BlogRightSidebar from "./blog/BlogRightSidebar";
import PageNotFound from "./Pages/404";
import ComingSoon from "./Pages/ComingSoon";
import Download from "./Pages/Download";
import FAQ from "./Pages/FAQ";
import ForgetPwd from "./Pages/ForgetPwd";
import Review from "./Pages/Review";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import ThankYou from "./Pages/ThankYou";
import React, { useEffect } from "react";
import HomeOne from "./HomeOne";
import HomeTwo from "./HomeTwo";
import HomeThree from "./HomeThree";
import { Route, Switch } from "react-router-dom";

function App() {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => { clearTimeout(timer) }
  }, []);
  localStorage.setItem("color", "color-1");
  return (
    <div className="App">

      <Switch
      >
        {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={DemoApp} /> */}
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomeOne} />
        <Route
          path={`${process.env.PUBLIC_URL}/home-one`}
          component={HomeOne}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/home-two`}
          component={HomeTwo}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/home-three`}
          component={HomeThree}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-details`}
          component={BlogDetails}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/advancing-hockey`}
          component={Blog1}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/advan-shopify`}
          component={Blog2}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/smb-transformation`}
          component={Blog3}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/generative-ai-and-shopify`}
          component={Blog4}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/revolution-ecommerce`}
          component={Blog5}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/dentist-generative-ai`}
          component={Blog6}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/digital-transformation`}
          component={Blog7}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/smarter-npc`}
          component={Blog8}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/brazil-smb-ecommerce`}
          component={Blog9}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/chatgpt-alternatives`}
          component={Blog10}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/food-truck-ai`}
          component={Blog11}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/shopify-management`}
          component={Blog12}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/brazil-ecommerce`}
          component={Blog13}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/small-stores-ai`}
          component={Blog14}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/shopify-3d-models`}
          component={Blog15}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/stable-difusion`}
          component={Blog16}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/shop-digital-age`}
          component={Blog17}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/google-blockchain`}
          component={Blog18}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/sports-ranking-analytics`}
          component={Blog19}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/ecommerce-security`}
          component={Blog20}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/ecommerce-race`}
          component={Blog21}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/pharmaceutical-generative-ai`}
          component={Blog22}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/shopify-summit`}
          component={Blog23}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-list`}
          component={BlogList}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-left-sidebar`}
          component={BlogLeftSidebar}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-right-sidebar`}
          component={BlogRightSidebar}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-leftside`}
          component={BlogLeftSide}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-rightside`}
          component={BlogRightSide}
        />
        <Route path={`${process.env.PUBLIC_URL}/sign-in`} component={SignIn} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/sign-up`}
          component={SignUp}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/forget-password`}
          component={ForgetPwd}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/thank-you`}
          component={ThankYou}
        />
        <Route path={`${process.env.PUBLIC_URL}/review`} component={Review} />
        <Route
          path={`${process.env.PUBLIC_URL}/404`}
          component={PageNotFound}
        />
        <Route path={`${process.env.PUBLIC_URL}/faq`} component={FAQ} />
        <Route
          path={`${process.env.PUBLIC_URL}/download`}
          component={Download}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/coming-soon`}
          component={ComingSoon}
        />
        <Route component={PageNotFound} />
      </Switch>

    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import Menu from "../components/Navbar";


const BlogDetails = () => {
    useEffect(() => {
        let timer = setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
        return () => { clearTimeout(timer) }
    }, []);
    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
    });

    const color = localStorage.getItem("color");
    useEffect(() => {

        if (color === "color-1")
            setBgImg({
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
            });
        else if (color === "color-2")
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/team-img-bg.png)` })
        else
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)` })
    }, [color]);


    return (
        <div>
            <Menu blog={true} />
            <div className="page-margin">
                <div className="breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                                <h2 className="title">
                                    blog<span> Details</span>
                                </h2>
                            </div>
                            <div className="col-md-6 col-sm-6 col-text-center">
                                <nav aria-label="breadcrumb" className="blog-bradcrumb ">
                                    <ol className="breadcrumb bg-transparent mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <a href="javascript" onClick={(e) => { e.preventDefault() }}>Blog Details</a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="blog-item">
                                    <div style={bgImg} className="blog-block" >
                                        <div className="blog-box">
                                            <div className="overflow-hidden">
                                                <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/blog/blog15.png"} alt="blog" className="img-fluid" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-text">
                                        <h6>19 September 2023</h6>
                                        <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                            <h3 className="blog-head">
                                                Enhance Shopping Experiences with Shopify’s Powerful New 3D Model Capability{" "}
                                            </h3>
                                        </a>
                                        <div className="blog-divider"></div>
                                        <div className="blog-description">
                                            <p>Shopify recently launched built-in support for interactive 3D models, enabling merchants to help customers visualize products from every angle and make more informed purchasing decisions.</p>
                                            <p>Early data indicates integrating 3D pays dividends for forward-thinking brands.</p>
                                            <p>&nbsp;</p>
                                            <p>Outdoor retailer Backcountry.com saw conversion lift over 20% on tents and climbing gear by letting customers manipulate 3D models to inspect details closely.</p>
                                            <p>&nbsp;</p>
                                            <p>Jewelry brand Vrai &amp; Oro&rsquo;s ultra-realistic 3D ring visualizer led to 70% higher engagement and 11% more conversions according to their case study.</p>
                                            <p>&nbsp;</p>
                                            <p>The key is presenting 3D models aligned to products - not just for novelty&rsquo;s sake, but to solve shopper questions and uncertainties.</p>
                                            <p>&nbsp;Do textures represent true color?</p>
                                            <p>How is it constructed?</p>
                                            <p>Does the fit seem right?</p>
                                            <p>&nbsp;</p>
                                            <p>3D answers decisively.</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>Shopify&rsquo;s built-in 3D support accepts glTF, OBJ, STL, and 15+ other formats, then automatically converts to an optimized 3D experience:</p>
                                            <p>&nbsp;</p>
                                            <h3><strong><u>Example of model file uploading and processing in Shopify</u></strong></h3>
                                            <p>Shoppers can pan, zoom, and rotate models directly on product pages to inspect from all angles just like holding an item:</p>
                                            <p>&nbsp;</p>
                                            <p><u>Embedded 3D model on product page </u></p>
                                            <p>&nbsp;</p>
                                            <p>To maximize impact:</p>
                                            <p>&nbsp;</p>
                                            <p>🔹 Strategically identify high-consideration categories where 3D adds the most value like jewelry, furniture, sporting goods, high-fashion apparel.</p>
                                            <p>&nbsp;</p>
                                            <p>🔹 Create or source highly realistic 3D models that accurately reflect products. Leverage professional 3D artists and tools if needed.</p>
                                            <p>&nbsp;</p>
                                            <p>🔹 Optimize lighting, camera angles, textures not just for aesthetics but to highlight nuances important to purchasing factors.</p>
                                            <p>&nbsp;</p>
                                            <p>🔹 Make 3D models prominent in page layouts - don&rsquo;t hide this sales-driving enhancement.</p>
                                            <p>&nbsp;</p>
                                            <p>🔹 Analyze metrics like conversions, dwell time, rotation counts to validate 3D ROI and inform future usage.</p>
                                            <p>&nbsp;</p>
                                            <p>Shopify&rsquo;s 3D support removes major friction standing between retailers and more immersive ecommerce experiences. Actively start testing where 3D models can add value, then scale to more products as capabilities grow. The data shows it drives sales while delighting customers.</p>
                                            <p>&nbsp;</p>
                                            <p>Have you explored using 3D for your store yet?</p>
                                            <p>&nbsp;</p>
                                            <p>Please share your thoughts or questions!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copyright-section">
                                    <p>2023 Copyright &copy; powered by Pagarba</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDetails;

import React, { useEffect, useState } from "react";
import Menu from "../components/Navbar";


const BlogDetails = () => {
    useEffect(() => {
        let timer = setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
        return () => { clearTimeout(timer) }
    }, []);
    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
    });

    const color = localStorage.getItem("color");
    useEffect(() => {

        if (color === "color-1")
            setBgImg({
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
            });
        else if (color === "color-2")
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/team-img-bg.png)` })
        else
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)` })
    }, [color]);


    return (
        <div>
            <Menu blog={true} />
            <div className="page-margin">
                <div className="breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                                <h2 className="title">
                                    blog<span> Details</span>
                                </h2>
                            </div>
                            <div className="col-md-6 col-sm-6 col-text-center">
                                <nav aria-label="breadcrumb" className="blog-bradcrumb ">
                                    <ol className="breadcrumb bg-transparent mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <a href="javascript" onClick={(e) => { e.preventDefault() }}>Blog Details</a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="blog-item">
                                    <div style={bgImg} className="blog-block" >
                                        <div className="blog-box">
                                            <div className="overflow-hidden">
                                                <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/blog/blog4.png"} alt="blog" className="img-fluid" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-text">
                                        <h6>26 July 2023</h6>
                                        <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                            <h3 className="blog-head">
                                                Boosting Success for Your Shopify Ecommerce Store with Generative AI and ChatGPT                                         </h3>
                                        </a>
                                        <div className="blog-divider"></div>
                                        <div className="blog-description">
                                            <p>Ecommerce businesses in Brazil and other South American countries have seen rapid growth in recent years. Online retail sales in Brazil are projected to reach nearly $40 billion USD by 2025.</p>
                                            <p>However, succeeding in these competitive markets presents challenges for small and medium businesses (SMBs).</p>
                                            <p>Integrating cutting-edge generative AI technology like ChatGPT and Claude AI with Shopify can give SMB ecommerce merchants a boost.</p>
                                            <p>&nbsp;In this post, we'll explore how to leverage AI to enhance Shopify stores targeting Brazil and other South American markets.</p>
                                            <p><strong>&nbsp;Generative AI Overview</strong></p>
                                            <p>Generative AI refers to machine learning models that can generate new content and output based on patterns in data they are trained on.</p>
                                            <p>Two leading examples are ChatGPT from Anthropic and Claude from Anthropic. These conversational AIs can understand natural language prompts and respond with remarkably human-like outputs like text, code, audio, and more.</p>
                                            <p><em><u>Key benefits generative AI offers ecommerce businesses include:</u></em></p>
                                            <ul>
                                                <li>Automating content creation
                                                    <ul>
                                                        <li>product descriptions, blog posts, social media captions</li>
                                                    </ul>
                                                </li>
                                                <li>Answering customer questions with natural language</li>
                                                <li>Translating content to local languages like Portuguese and Spanish</li>
                                                <li>Optimizing on-page SEO through AI-generated metadata and alt text</li>
                                                <li>Providing code examples to build custom functionality</li>
                                                <li>Integrating a generative AI model like ChatGPT or Claude with Shopify unlocks these benefits for SMB online stores.</li>
                                            </ul>
                                            <p><strong>Ways to Integrate Generative AI with Shopify</strong></p>
                                            <p></p>
                                            <p>There are a few methods Shopify merchants can use to connect a generative AI assistant to their stores:</p>
                                            <p><u>&nbsp;1. Shopify Apps</u></p>
                                            <p>Several apps on the Shopify App Store provide ready-made integrations with ChatGPT and other models:</p>
                                            <ul>
                                                <li>Ryviu App
                                                    <ul>
                                                        <li>Allows merchants to connect ChatGPT to handle common customer service queries and generate product descriptions.</li>
                                                    </ul>
                                                </li>
                                                <li>ESS ChatGPT
                                                    <ul>
                                                        <li>Uses ChatGPT for 24/7 automated support bots on your store.</li>
                                                    </ul>
                                                </li>
                                                <li>io
                                                    <ul>
                                                        <li>Marketing focused app that provides generative content and email campaigns.</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <p>These apps provide pre-built bridges to AI with customizable settings and permissions.</p>
                                            <p><u>&nbsp;2. Custom Integration via API</u></p>
                                            <p>Shopify offers a REST Admin API that developers can use to build custom integrations between stores and external services like generative AIs.</p>
                                            <p>With ChatGPT's API, Claude's API, or an intermediary service like Anthropic's Constitutional AI Assistant API, developers can connect the AI to:</p>
                                            <ul>
                                                <li>Sync products and pull data like titles and descriptions to auto-generate text</li>
                                                <li>Allow staff to query the AI via custom admin dashboard sections</li>
                                                <li>Create webhooks on store events like abandoned carts to trigger AI-generated emails</li>
                                            </ul>
                                            <p>This deeper custom integration requires more technical expertise but enables more advanced use cases tailored to the store's needs.</p>
                                            <p><u>&nbsp;3. Staff Usage of AI</u></p>
                                            <p>Even without an automated integration, ecommerce staff can utilize generative AI manually to boost productivity:</p>
                                            <ul>
                                                <li>Use Claude or ChatGPT to generate product descriptions, blog posts, and other content</li>
                                                <li>Query the AI to translate existing descriptions and pages to Portuguese, Spanish etc.</li>
                                                <li>Get AI-optimized SEO title/meta descriptions for target keywords</li>
                                                <li>Ask for code examples when building custom Shopify sections and functionality</li>
                                            </ul>
                                            <p>Equipping the marketing and tech teams with access to AI assistants makes processes like localization and content creation much faster.</p>
                                            <p><u>&nbsp;Examples and Use Cases</u></p>
                                            <p>Some specific examples of how to leverage generative AI for ecommerce success in Brazil and South America include:</p>
                                            <p><strong><em>Localized Content</em></strong></p>
                                            <p>Use the AI to translate existing English product descriptions, FAQs, and blog posts to Portuguese for the Brazilian market.</p>
                                            <p>I.e :</p>
                                            <p><strong><em>Human</em></strong>: Please translate this English product description to Portuguese:</p>
                                            <p>"Our premium leather wallet is crafted from full-grain leather for enhanced durability. It features an ultra-slim, minimalist design with 6 card slots and an ID window. This lightweight wallet easily fits in a front pocket, reducing bulk while still providing room for daily essentials."</p>
                                            <p><strong><em>AI</em></strong>: Aqui est&aacute; a descri&ccedil;&atilde;o do produto traduzida para o portugu&ecirc;s:</p>
                                            <p>"Nossa carteira premium de couro &eacute; feita de couro nobuck para maior durabilidade. Ela possui um design minimalista e ultrafino com 6 compartimentos para cart&otilde;es e uma janela para identidade. Esta carteira leve cabe facilmente no bolso da frente, reduzindo volume mas ainda provendo espa&ccedil;o para itens essenciais di&aacute;rios."</p>
                                            <p>This allows efficiently creating localized translations at scale.</p>
                                            <p><em><u>SEO Optimization</u></em></p>
                                            <p>Use Claude or ChatGPT to generate keyword-optimized titles and meta descriptions for Brazilian search terms:</p>
                                            <p><strong><em>Human</em></strong>: Please generate a meta title and description targeting the keyword "carteira masculina de couro" for the Brazilian market.</p>
                                            <p><strong><em>AI</em></strong>:&nbsp;</p>
                                            <p>Title:</p>
                                            <p>Carteiras Masculinas de Couro Premium | Dur&aacute;veis e Minimalistas</p>
                                            <p>Description:</p>
                                            <p>Compre carteiras masculinas slim de couro genu&iacute;no. Modelos minimalistas, resistentes e funcionais para guardar cart&otilde;es e documentos no dia a dia. Envio r&aacute;pido e seguro.</p>
                                            <p>This on-page SEO optimization can help drive organic traffic from local search engines.</p>
                                            <p><em><u>Customer Support</u></em></p>
                                            <p>Integrate an AI chatbot to instantly answer common customer questions in Portuguese 24/7:</p>
                                            <p><strong><em>Customer</em></strong>: "Meu pedido ainda n&atilde;o chegou, o que aconteceu?"</p>
                                            <p><strong><em>AI</em></strong>: "Sinto muito pelo atraso no seu pedido. Por favor me forne&ccedil;a o n&uacute;mero do pedido ou o email usado na compra para que eu possa verificar o status de entrega e lhe darei uma atualiza&ccedil;&atilde;o assim que poss&iacute;vel."</p>
                                            <p>Automated AI support provides a better buyer experience.</p>
                                            <p><em><u>Product Generation</u></em></p>
                                            <p>For custom products like apparel, use AI to generate hundreds of new designs targeted to Brazilian culture and tastes. Staff can then curate the best ones.</p>
                                            <p>The possibilities are truly endless when you integrate generative AI into your Shopify store!</p>
                                            <p>Whether you need supercharged content creation, instant translations, or 24/7 customer support, AI can take your ecommerce business to the next level in Brazil and beyond.</p>
                                            <p>Don't leave this powerful technology on the table -</p>
                                            <p>partner with the experts at&nbsp;<strong><em>Pagarba Solutions&nbsp;</em></strong>today to implement AI and watch your Latin American Shopify sales grow.</p>
                                            <p>Our team has the knowledge and experience to help your business succeed with the help of amazing AI tools like ChatGPT.</p>
                                            <p>Reach out now to learn more about boosting your Shopify store's success with generative AI!</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copyright-section">
                                    <p>2023 Copyright &copy; powered by Pagarba</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDetails;

import React, { useEffect, useState } from "react";
import Menu from "../components/Navbar";


const BlogDetails = () => {
    useEffect(() => {
        let timer = setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
        return () => { clearTimeout(timer) }
    }, []);
    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
    });

    const color = localStorage.getItem("color");
    useEffect(() => {

        if (color === "color-1")
            setBgImg({
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
            });
        else if (color === "color-2")
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/team-img-bg.png)` })
        else
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)` })
    }, [color]);


    return (
        <div>
            <Menu blog={true} />
            <div className="page-margin">
                <div className="breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                                <h2 className="title">
                                    blog<span> Details</span>
                                </h2>
                            </div>
                            <div className="col-md-6 col-sm-6 col-text-center">
                                <nav aria-label="breadcrumb" className="blog-bradcrumb ">
                                    <ol className="breadcrumb bg-transparent mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <a href="javascript" onClick={(e) => { e.preventDefault() }}>Blog Details</a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="blog-item">
                                    <div style={bgImg} className="blog-block" >
                                        <div className="blog-box">
                                            <div className="overflow-hidden">
                                                <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/blog/blog7.jpg"} alt="blog" className="img-fluid" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-text">
                                        <h6>10 August 2023</h6>
                                        <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                            <h3 className="blog-head">
                                                How decentraland used AI to create smarter NPCs,{" "}
                                            </h3>
                                        </a>
                                        <div className="blog-divider"></div>
                                        <div className="blog-description">
                                            <section>
                                                <p>Step into a world where artificial intelligence and imagination collide, as Decentraland pioneers a groundbreaking leap into the future. Prepare to be mesmerized as the Ethereum-based universe weaves its spell, now enhanced with the enchanting touch of AI-powered non-player characters (NPCs), co-created with the visionary minds at Inworld.</p>
                                                <p><strong><em>Picture this:</em></strong></p>
                                                <p>&nbsp;a metaverse bursting with life, teeming with avatars who possess the wit and wisdom of AI. Yes, you read that right! Decentraland's ingenious collaboration with Inworld has given birth to NPCs that will bewitch you with their charm. These digital denizens, much like chatbots, beckon you to converse, engaging in enlightening dialogues that transcend reality.</p>
                                                <p><strong><em>Meet the Characters</em></strong></p>
                                                <p>Step into Decentraland's captivating Genesis Plaza and encounter a trio of captivating NPCs: Simone the Robot, the knowledgeable oracle of the metaverse; Doge, the merry meme master; and Aisha, the spirited gaming streamer and former skater, bringing her vibrant personality to the virtual realm. Each NPC, a universe in themselves, gifts you the opportunity to explore a cornucopia of narratives.</p>
                                                <p><strong><em>The Power of Imagination</em></strong></p>
                                                <p>But wait, there's more! Are you an aspiring creator yearning to infuse your personal touch into this tapestry of possibilities? Decentraland and Inworld invite you to craft your very own NPCs. Design them from the ground up, imbue them with history, context, and motivation, and watch as they spring to life, enchanting all who cross their path. Inworld, the AI rising star, has already secured a meteoric rise, raising $50 million at a valuation of $500 million.</p>
                                                <p>They've harnessed the power of ChatGPT on steroids, and you're at the helm, steering the ship of creativity.</p>
                                                <p><strong><em>The Unveiling of Immersion</em></strong></p>
                                                <p>But why AI, you ask? Brace yourself for an immersive awakening. Imagine stepping into an art gallery, where NPCs regale you with tales of the artists and their masterpieces. Envision player-created landscapes, each adorned with a virtual host who showcases its features with grace.</p>
                                                <p>This is the metamorphosis Decentraland promises, a symphony of narratives that seamlessly blend with your reality.</p>
                                                <p><strong><em>A World United, Voices Diverse</em></strong></p>
                                                <p>Decentraland embraces the diverse tongues of the world, breaking down linguistic barriers with AI's power to comprehend different languages. No longer constrained by the dominion of English, personalized experiences emerge, amplifying the thrill of localized encounters. Decentraland, the melting pot of cultures, ushers in an era where unity is strength and diversity is celebrated.</p>
                                                <p><strong><em>AI Shapes the Future</em></strong></p>
                                                <p>Hold onto your seats as we dive deeper into the rabbit hole. AI's creative genius does not stop at in-game marvels. Decentraland's own Decentraland Foundation employs OpenAI's ChatGPT to distill intricate development documents, ensuring a holistic view of the metaverse's evolution.</p>
                                                <p>The possibilities extend to generative AI crafting concept art, audio, and animations, all while creators dare to dream of AI-generated 3D models, poised to bridge the chasm of complexity.</p>
                                                <p><strong><em>A Dance with Creation</em></strong></p>
                                                <p>Open your eyes to Shape-E and GET3D, AI allies poised to pave the way for creators, ushering in a dawn where even novices can give birth to prototypes. This revolution stands poised to level the playing field, transforming creators into visionaries, sculpting their dreams with unprecedented ease. Embrace this symphony of possibilities and watch the world unfurl at your fingertips.</p>
                                                <p><strong><em>A Balancing Act</em></strong></p>
                                                <p>Amidst this crescendo of innovation, a note of caution emerges. As AI's embrace widens, tread carefully on the precipice of originality. Beware the lure of mediocrity, for within AI's realm lies the danger of regurgitating existing content without soul. Decentraland's Yemel Jardi, ever the sage, sounds the alarm against a world consumed by derivative creations, urging us to strive for uncharted realms of value.</p>
                                                <p><strong><em>Empowering the Dreamweavers</em></strong></p>
                                                <p>Does AI threaten to usurp the artisan's throne? Fear not, for Yemel Jardi envisions a world where AI elevates creators, enabling them to channel their energies towards the sublime. AI, a beacon of innovation, stands as the ultimate translator, deciphering the intricacies of creation while preserving the essence of humanity. The stage is set for an era where AI is an ally, not a competitor.</p>
                                                <p><strong><em>A Portal to Knowledge</em></strong></p>
                                                <p>As Decentraland pushes boundaries, it paves a path to wisdom through SophiaVerse. An ethereal realm conceived in collaboration with Hanson Robotics, SophiaVerse beckons the curious to unlock the mysteries of AI research and development. Journey alongside NPC creator tools, embarking on an odyssey of understanding, as AI&rsquo;s mysteries unfurl before your very eyes.</p>
                                                <p>A&nbsp;<strong><em>Spectacle Awaits</em></strong></p>
                                                <p>Hold your breath for the AI World Fair, a grand spectacle slated for October. Three days of brilliance, where AI luminaries and avant-garde brands converge, shaping new vistas within the digital realm. Step forth, dear traveler, and be among the first to witness AI&rsquo;s evolution, set amidst the breathtaking tapestry of Decentraland.</p>
                                                <p>A&nbsp;<strong><em>Symphony of Creation</em></strong></p>
                                                <p>In a world gripped by knowledge disparity, Yemel Jardi champions the cause of education. Just as smartphones revolutionized filmmaking, AI-driven tools are poised to redefine creation for the uninitiated. A world where AI empowers the dreamers, where barriers dissolve and novices emerge as creators.</p>
                                                <p><strong><em>The Awakening</em></strong></p>
                                                <p>Decentraland's journey is an ode to the endless bounds of possibility. Through AI's alchemy, it awakens the creators within us all, crafting a symphony where technology dances with imagination. Unshackle your mind, embrace the future, and dare to dream, for in Decentraland's embrace, the fantastical becomes reality.</p>
                                            </section>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copyright-section">
                                    <p>2023 Copyright &copy; powered by Pagarba</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDetails;

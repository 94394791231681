import React, { useEffect } from "react";
import Slider from "react-slick";

const Blog = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => { clearTimeout(timer) }
  }, []);
  // const [bgImg, setBgImg]= useState({
  //   backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
  // });

  // const color= localStorage.getItem("color");

  // useEffect(()=>{

  //   if(color === "color-1")
  //    setBgImg( {
  //     backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
  //   });
  //   else if(color === "color-2")
  //   setBgImg({ backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-2/team-img-bg.png)`})
  //   else 
  //   setBgImg({backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)`})
  // },[color]);

  //Slick slider Options
  const options = {
    arrows: false,
    accessibility: false,
    infinite: true,
    dots: true,
    dotClass: ".blog-carousel",
    margin: 30,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
        },
      }
    ],
  };

  // Dynamic Blog Data Easy to Update
  let data = [
    {
      title:
        "Advancing Hockey Organizations: Integrating Innovative Technologies with Shopify and TeamSnap",
      link: "advancing-hockey",
      date: "17 July 2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog1.png`,
      username: "Jennifer",
      hits: "10",
      comments: "0",
    },
    {
      title:
        "Advan with Shopify and Transforming Hockey Tournaments with Innovative Technologies: Exploring web3, NFTs & chatgpt use cases",
      link: "advan-shopify",
      date: "18 July 2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog2.png`,
      username: "Jennifer",
      hits: "10",
      comments: "0",
    },
    {
      title:
        "Enhancing SMB Digital Transformation: Zero Trust, ZKPs, DiDs, Verifiable Credentials, and Bitcoin Integration with Shopify",
      link: "smb-transformation",
      date: "19 July 2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog3.png`,
      username: "Jennifer",
      hits: "6",
      comments: "0",
    },
    {
      title:
        "Boosting Success for Your Shopify Ecommerce Store with Generative AI and ChatGPT",
      link: "generative-ai-and-shopify",
      date: "26 July 2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog4.png`,
      username: "Jennifer",
      hits: "10",
      comments: "0",
    },
    {
      title:
        "Revolutionizing Small and Medium-sized Ecommerce Businesses with Generative AI, ChatGPT, and NLP Techniques on Shopify",
      link: "revolution-ecommerce",
      date: "07 August 2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog5.png`,
      username: "Jennifer",
      hits: "10",
      comments: "0",
    },
    {
      title:
        "Leveraging the Power of Generative AI, NLP, and ChatGPT in Dentistry and Orthodontics",
      link: "dentist-generative-ai",
      date: "08 August 2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog6.png`,
      username: "Jennifer",
      hits: "10",
      comments: "0",
    },
    {
      title:
        "Revolutionizing Shopify Through Generative AI: A Path to Digital Transformation",
      link: "digital-transformation",
      date: "10 August 2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog8.png`,
      username: "Jennifer",
      hits: "6",
      comments: "0",
    },
    {
      title:
        "How decentraland used AI to create smarter NPCs",
      link: "smarter-npc",
      date: "10 August 2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog7.jpg`,
      username: "Jennifer",
      hits: "10",
      comments: "0",
    },
    {
      title:
        "Ignite a Tilian SMB Ecommerce:iDs), Smart Contracts, and AI-Driven Insights!",
      link: "brazil-smb-ecommerce",
      date: "14 August 2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog9.png`,
      username: "Jennifer",
      hits: "10",
      comments: "0",
    },
    {
      title:
        "Exploring 11 ChatGPT Alternatives That Will Mesmerize You in 2023 (Free & Paid)",
      link: "chatgpt-alternatives",
      date: "16 August 2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog10.png`,
      username: "Jennifer",
      hits: "10",
      comments: "0",
    },
    {
      title:
        "Elevate Your Food Truck with AI-Driven Innovation",
      link: "food-truck-ai",
      date: "23 August 2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog11.png`,
      username: "Jennifer",
      hits: "6",
      comments: "0",
    },
    {
      title:
        "Take Your Shopify POS to the Next Level: Advanced Inventory Management Techniques for Retailers & SMBs",
      link: "shopify-management",
      date: "25 August 2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog12.png`,
      username: "Jennifer",
      hits: "10",
      comments: "0",
    },
    {
      title:
        "The Future of Ecommerce in Brazil: How SMBs Can Thrive with Shopify and AI",
      link: "brazil-ecommerce",
      date: "29 August 2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog13.jpg`,
      username: "Jennifer",
      hits: "6",
      comments: "0",
    },
    {
      title:
        "How Small Sporting Goods Stores Can Compete using AI",
      link: "small-stores-ai",
      date: "14 September  2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog14.png`,
      username: "Jennifer",
      hits: "10",
      comments: "0",
    },
    {
      title:
        "Enhance Shopping Experiences with Shopify’s Powerful New 3D Model Capability",
      link: "shopify-3d-models",
      date: "19 September  2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog15.png`,
      username: "Jennifer",
      hits: "10",
      comments: "0",
    },
    {
      title:
        "Leveraging 3D & AI to Enhance Product Visuals and Drive Sales",
      link: "stable-difusion",
      date: "21 September  2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog16.jpg`,
      username: "Jennifer",
      hits: "10",
      comments: "0",
    },
    {
      title:
        "Bringing The Hobby Shop into the Digital Age with AI",
      link: "shop-digital-age",
      date: "22 September  2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog17.png`,
      username: "Jennifer",
      hits: "10",
      comments: "0",
    },
    {
      title:
        "Google Bets Big on Blockchain Despite Crypto Winter Chill",
      link: "google-blockchain",
      date: "25 September  2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog18.png`,
      username: "Jennifer",
      hits: "10",
      comments: "0",
    },
    {
      title:
        "Fixing the Broken Youth Sports Ranking System: How AI and Blockchain Can Revolutionize Player Analytics",
      link: "sports-ranking-analytics",
      date: "27 September  2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog19.png`,
      username: "Jennifer",
      hits: "10",
      comments: "0",
    },
    {
      title:
        "Is your Ecommerce Business Vulnerable? How to Strengthen Security Against Online Threats",
      link: "ecommerce-security",
      date: "10 October  2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog20.png`,
      username: "Jennifer",
      hits: "10",
      comments: "0",
    },
    {
      title:
        "The Ecommerce Platform Arms Race: Who Will Seize the AI Advantage? ",
      link: "ecommerce-race",
      date: "11 October  2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog21.png`,
      username: "Jennifer",
      hits: "10",
      comments: "0",
    },
    {
      title:
        "The Transformative Potential of Generative AI in Drug Discovery ",
      link: "pharmaceutical-generative-ai",
      date: "31 October  2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog22.jpg`,
      username: "Jennifer",
      hits: "10",
      comments: "0",
    },
    {
      title:
        "Pagarba at RD Summit 2023: Captivating Customers with Optimized Shopify Mobile Stores ",
      link: "shopify-summit",
      date: "06 December  2023",
      photo: `${process.env.PUBLIC_URL}/assets/images/blog/blog23.png`,
      username: "Jennifer",
      hits: "10",
      comments: "0",
    },
  ];

  // Dynamic Blog Data Loop
  let DataList = data.map((val, i) => {
    return (
      <div className="blog-item" key={i}>
        <div className="blog-block" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)` }} >
          <div className="blog-box">
            <div className="overflow-hidden">
              <a href={val.link}>
                <img src={val.photo} className="img-fluid" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="blog-text">
          <h6>{val.date}</h6>
          <a href={val.link}>
            <h3>{val.title}</h3>
          </a>
          <h5>
            posted by {val.username}, {val.hits} hits, {val.comments} comment
          </h5>
        </div>
      </div>
    );
  });

  return (
    <section id="blog" className="blog">
      <div className="about-decor">
        <div className="about-circle1">
          <img src={`${process.env.PUBLIC_URL}/assets/images/team1.png`} className="img-fluid" alt="team1" />
        </div>
        <div className="about-circle2">
          <img src={`${process.env.PUBLIC_URL}/assets/images//main-banner1.png`} className="img-fluid" alt="main-banner1" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h2 className="title">
              latest <span>blog</span>
            </h2>
          </div>
          <div className="col-sm-12">
            <Slider
              className="blog-carousel owl-carousel owl-theme rounded-dots slick-margin"
              {...options}
            >
              {DataList}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;

import React, { useEffect, useState } from "react";
import Menu from "../components/Navbar";


const BlogDetails = () => {
    useEffect(() => {
        let timer = setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
        return () => { clearTimeout(timer) }
    }, []);
    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
    });

    const color = localStorage.getItem("color");
    useEffect(() => {

        if (color === "color-1")
            setBgImg({
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
            });
        else if (color === "color-2")
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/team-img-bg.png)` })
        else
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)` })
    }, [color]);


    return (
        <div>
            <Menu blog={true} />
            <div className="page-margin">
                <div className="breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                                <h2 className="title">
                                    blog<span> Details</span>
                                </h2>
                            </div>
                            <div className="col-md-6 col-sm-6 col-text-center">
                                <nav aria-label="breadcrumb" className="blog-bradcrumb ">
                                    <ol className="breadcrumb bg-transparent mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <a href="javascript" onClick={(e) => { e.preventDefault() }}>Blog Details</a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="blog-item">
                                    <div style={bgImg} className="blog-block" >
                                        <div className="blog-box">
                                            <div className="overflow-hidden">
                                                <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/blog/blog16.jpg"} alt="blog" className="img-fluid" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-text">
                                        <h6>21 September 2023</h6>
                                        <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                            <h3 className="blog-head">
                                                Leveraging 3D & AI to Enhance Product Visuals and Drive Sales{" "}
                                            </h3>
                                        </a>
                                        <div className="blog-divider"></div>
                                        <div className="blog-description">
                                            <p>As consumers do more shopping online, product images have become critical to ecommerce success. High quality, consistent and enticing visuals can boost conversion rates and sales. But obtaining such product photos can be costly and time consuming, especially for small businesses.</p>
                                            <p>&nbsp;</p>
                                            <p>That's why AI tools like stable diffusion are so valuable. They make creating 3D product renderings quick, affordable and customizable - enabling smaller retailers to visually compete with giants.</p>
                                            <p>&nbsp;</p>
                                            <p>We recently helped a small, local sporting goods shop do just that by implementing AI-generated 3D product visuals across their Shopify store.</p>
                                            <p>&nbsp;</p>
                                            <p><strong><em>Here's an overview:</em></strong></p>
                                            <p><strong><em>&nbsp;</em></strong></p>
                                            <p><strong><u>The Challenge:&nbsp; </u></strong></p>
                                            <p>Outdated, inconsistent product photos that didn't align with their premium brand identity. Relying on suppliers resulted in a disconnected customer experience.</p>
                                            <p>&nbsp;</p>
                                            <p><strong><u>Our Solution:</u></strong></p>
                                            <p>We integrated stable diffusion to cost-effectively create on-brand 3D renderings of their entire catalog. The AI images were then enhanced by our visual experts.</p>
                                            <p>&nbsp;</p>
                                            <p><strong><u>The Results:</u></strong></p>
                                            <p>Conversion rates increased 22% after launching the enhanced visuals.</p>
                                            <p>The immersive 3D images improved engagement as customers could view products from all angles. The owner estimated a 14% revenue increase from the updated product imagery.</p>
                                            <p>&nbsp;</p>
                                            <p>By leveraging generative AI, we quickly overhauled their photography at a fraction of the cost of a traditional photo shoot. The visually-optimized Shopify store better conveyed their premier positioning. Most importantly, it led directly to business growth.</p>
                                            <p>&nbsp;</p>
                                            <p><strong><em>Want to Explore How AI-Powered Visuals Could Benefit Your Ecommerce?</em></strong></p>
                                            <p><strong><em>&nbsp;</em></strong></p>
                                            <p>This example highlights the immense potential of 3D and AI for online retail. Let our experts show you how during a <strong><em>free consultation.</em></strong></p>
                                            <p>&nbsp;</p>
                                            <p>Contact Pagarba Solutions today at <a href="mailto:info@pagarba.io">info@pagarba.io</a></p>
                                            <p>&nbsp;to discuss how we can implement cutting-edge AI to create stunning product visuals, boost engagement and drive sales for your Shopify store.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copyright-section">
                                    <p>2023 Copyright &copy; powered by Pagarba</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDetails;

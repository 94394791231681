import React, { useEffect, useState } from "react";
import Menu from "../components/Navbar";


const BlogDetails = () => {
    useEffect(() => {
        let timer = setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
        return () => { clearTimeout(timer) }
    }, []);
    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
    });

    const color = localStorage.getItem("color");
    useEffect(() => {

        if (color === "color-1")
            setBgImg({
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
            });
        else if (color === "color-2")
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/team-img-bg.png)` })
        else
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)` })
    }, [color]);


    return (
        <div>
            <Menu blog={true} />
            <div className="page-margin">
                <div className="breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                                <h2 className="title">
                                    blog<span> Details</span>
                                </h2>
                            </div>
                            <div className="col-md-6 col-sm-6 col-text-center">
                                <nav aria-label="breadcrumb" className="blog-bradcrumb ">
                                    <ol className="breadcrumb bg-transparent mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <a href="javascript" onClick={(e) => { e.preventDefault() }}>Blog Details</a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="blog-item">
                                    <div style={bgImg} className="blog-block" >
                                        <div className="blog-box">
                                            <div className="overflow-hidden">
                                                <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/blog/blog17.png"} alt="blog" className="img-fluid" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-text">
                                        <h6>22 September 2023</h6>
                                        <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                            <h3 className="blog-head">
                                                Bringing The Hobby Shop into the Digital Age with AI{" "}
                                            </h3>
                                        </a>
                                        <div className="blog-divider"></div>
                                        <div className="blog-description">
                                            <p>John woke up anxious about his model train store, a beloved community hobby shop his dad started 40 years ago. Only a few loyal customers still visited the aging store with its dusty train sets and static vintage displays.</p>
                                            <p>&nbsp;</p>
                                            <p>Foot traffic had slowed to a trickle as buyers shifted to the convenience of online stores like Amazon and eBay. John's declining revenue put the future of the store in jeopardy.</p>
                                            <p>&nbsp;</p>
                                            <p>John knew he needed to modernize his Shopify ecommerce store to compete in 2023. But he lacked the technical skills to leverage new technologies like artificial intelligence (AI), generative AI, and 3D product imaging on his own.</p>
                                            <p>&nbsp;</p>
                                            <p>By harnessing innovations like predictive search algorithms, conversational chatbots, and immersive 3D product visuals, small niche hobby retailers can thrive digitally.</p>
                                            <p>&nbsp;</p>
                                            <p>Pagarba Solutions specializes in transforming and optimizing ecommerce stores leveraging turnkey AI tools. We tailor comprehensive Shopify store strategies powered by data-driven solutions.</p>
                                            <p>&nbsp;</p>
                                            <p><strong>Our AI-enhanced Shopify offerings include:</strong></p>
                                            <p>&nbsp;</p>
                                            <ul>
                                                <li>Generative AI Product Descriptions
                                                    <ul>
                                                        <li>We create SEO-optimized, custom product descriptions designed to boost page rankings and conversions.</li>
                                                    </ul>
                                                </li>
                                                <li>Conversational Commerce Chatbots
                                                    <ul>
                                                        <li>Our intelligent chatbots engage customers 24/7 via messaging apps and your store site to drive sales.</li>
                                                    </ul>
                                                </li>
                                                <li>3D &amp; Enhanced Product Images
                                                    <ul>
                                                        <li>We leverage generative AI to transform standard product photos into immersive 3D, lifestyle and enhanced images.</li>
                                                    </ul>
                                                </li>
                                                <li>Predictive Search
                                                    <ul>
                                                        <li>Our semantics-based search understands intent, guides customers to the right products, and reduces dead searches.</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <p>&nbsp;</p>
                                            <p>With Pagarba Solutions' help, John launched initiatives like 3D train set visuals and an AI-powered chatbot. His online store revenue now outpaces his physical location.</p>
                                            <p>&nbsp;</p>
                                            <p>The future is bright for hobby stores who embrace new technologies and optimize for ecommerce. By partnering with experts like Pagarba, niche retailers can find new customers and nurture communities online.</p>
                                            <p>&nbsp;</p>
                                            <p>John realizes his beloved community model train store can continue thriving for years to come in the digital age. AI and immersive visuals helped him bring the in-store experience into the modern ecommerce era.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copyright-section">
                                    <p>2023 Copyright &copy; powered by Pagarba</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDetails;

import React, { useEffect } from "react";

const Feature = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => { clearTimeout(timer) }
  }, []);
  // const [bgImg, setBgImg]= useState({
  //   backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/feature-bg.png)`
  // });

  // const color= localStorage.getItem("color");
  // useEffect(()=>{

  //   if(color === "color-1")
  //    setBgImg( {
  //     backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/feature-bg.png)`
  //   });
  //   else if(color === "color-2")
  //   setBgImg({ backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-2/feature-bg1.png)`})
  //   else 
  //   setBgImg({backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-3/feature-bg1.png)`})
  // },[color]);  

  return (
    <section id="feature" className="feature" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/feature-bg.png)` }}>
      <div className="feature-decor">
        <div className="feature-circle1">
          <img src={`${process.env.PUBLIC_URL}/assets/images/feature2.png`} alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          {/* <div className="feature-phone">
            <img src={`${process.env.PUBLIC_URL}/assets/images/feature-test.png`} class="img-fluid" alt="" />
          </div> */}
          <div className="offset-lg-4 col-lg-8">
            <div className="row">
              <div className="col-sm-12 mrgn-md-top">
                <h2 className="title">
                  Shopify Ecommerce<span> Software Solutions</span>
                </h2>
              </div>
              <div className="col-12 col-md-6">
                <ul className="feature-style">
                  <li>
                    <div className="feature-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/1.png`} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <h3>AI Assistants</h3>
                    </div>
                    <div>
                      <p>
                        Ensure helpful, honest and effective AI interactions
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="feature-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/3.png`} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <h3> App Development</h3>
                    </div>
                    <div>
                      <p>
                        Build and launch apps faster the secure way
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="feature-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/6.png`} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <h3>RD Summit Brazil</h3>
                    </div>
                    <div>
                      <p>
                        Pagarba was present on Shopify Village in Sao Paulo, Brazil, 2023. Watch details below!
                      </p>
                    </div>

                    <div>
                      <iframe className="video-responsive" src={`${process.env.PUBLIC_URL}/assets/images/video.mp4`} autoplay="true" sandbox="allow-same-origin allow-scripts"  ></iframe>

                    </div>
                  </li>


                </ul>
              </div>
              <div className="col-12 col-md-6 sm-m-top">
                <ul className="feature-style">
                  <li>
                    <div className="feature-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/2.png`} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <h3>IT Optimization</h3>
                    </div>
                    <div>
                      <p>
                        Automate repetitive tasks to reduce workload
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="feature-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/4.png`} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <h3> Cloud Data Protection</h3>
                    </div>
                    <div>
                      <p>
                        Keep customer data ultra-secure with encryption
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="feature-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/6.png`} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <h3>Schedule a Free Consultation With Our Experts</h3>
                    </div>
                    <div>
                      <p>
                        Meet with our team to explore how our  services will empower fearless innovation tailored to your strategic growth objectives.
                      </p>
                    </div>
                  </li>

                  {/* <li>
                    <div className="feature-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/5.png`} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <h3> AI Assistants & Chatbots</h3>
                    </div>
                    <div>
                      <p>
                        Say hello to 24/7 customer support and personalized interactions. Pagarba's AI assistants and chatbots are designed to streamline your customer service, boost efficiency, and drive conversions. Let AI elevate your business presence, powered by Pagarba's expertise.                      </p>
                    </div>
                  </li> */}



                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;

import React, { useEffect, useState } from "react";
import Menu from "../components/Navbar";


const BlogDetails = () => {
    useEffect(() => {
        let timer = setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
        return () => { clearTimeout(timer) }
    }, []);
    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
    });

    const color = localStorage.getItem("color");
    useEffect(() => {

        if (color === "color-1")
            setBgImg({
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
            });
        else if (color === "color-2")
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/team-img-bg.png)` })
        else
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)` })
    }, [color]);


    return (
        <div>
            <Menu blog={true} />
            <div className="page-margin">
                <div className="breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                                <h2 className="title">
                                    blog<span> Details</span>
                                </h2>
                            </div>
                            <div className="col-md-6 col-sm-6 col-text-center">
                                <nav aria-label="breadcrumb" className="blog-bradcrumb ">
                                    <ol className="breadcrumb bg-transparent mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <a href="javascript" onClick={(e) => { e.preventDefault() }}>Blog Details</a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="blog-item">
                                    <div style={bgImg} className="blog-block" >
                                        <div className="blog-box">
                                            <div className="overflow-hidden">
                                                <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/blog/blog23.png"} alt="blog" className="img-fluid" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-text">
                                        <h6>06 December 2023</h6>
                                        <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                                            <h3 className="blog-head">
                                                Pagarba at RD Summit 2023: Captivating Customers with Optimized Shopify Mobile Stores
                                                {" "}
                                            </h3>
                                        </a>
                                        <div className="blog-divider"></div>
                                        <div className="blog-description">
                                            <p data-sourcepos="3:1-3:367">Pagarba Solutions, a leading mobile app development company specializing in Shopify, made a splash at the recent RD Summit 2023 in S&atilde;o Paulo, Brazil. The summit, a hub for marketing, sales, and innovation professionals, provided the perfect platform for Pagarba to showcase its expertise in crafting captivating and sales-driving mobile stores for Shopify businesses.</p>
                                            <p data-sourcepos="5:1-5:60"><strong>The Shopify Village: Where Optimization Meets Engagement</strong></p>
                                            <p data-sourcepos="7:1-7:241">Pagarba was prominently positioned within the dedicated Shopify Village, welcoming guests at booth SH05. Here, visitors experienced firsthand how Pagarba's solutions transform Shopify stores into mobile powerhouses. These solutions included:</p>
                                            <ul data-sourcepos="9:1-14:0">
                                                <li data-sourcepos="9:1-9:171"><strong>Mobile-first design:</strong>&nbsp;Pagarba prioritizes mobile-first design principles, ensuring a seamless and intuitive shopping experience tailored to the needs of mobile users.</li>
                                                <li data-sourcepos="10:1-10:183"><strong>Faster loading speeds:</strong>&nbsp;Pagarba optimizes mobile stores for speed, ensuring smooth navigation and quick product searches, keeping customers engaged and reducing cart abandonment.</li>
                                                <li data-sourcepos="11:1-11:157"><strong>High-quality visuals:</strong>&nbsp;Pagarba helps businesses showcase their products with stunning visuals, captivating customers and influencing purchase decisions.</li>
                                                <li data-sourcepos="12:1-12:196"><strong>Personalized shopping experiences:</strong>&nbsp;Pagarba leverages data to personalize the mobile shopping experience, offering relevant product recommendations and promotions, further engaging customers.</li>
                                                <li data-sourcepos="13:1-14:0"><strong>Seamless payment integration:</strong>&nbsp;Pagarba integrates seamlessly with leading payment gateways, enabling smooth and secure transactions on mobile devices.</li>
                                            </ul>
                                            <p data-sourcepos="15:1-15:52"><strong>Building Connections and Sharing Success Stories</strong></p>
                                            <p data-sourcepos="17:1-17:199">The Pagarba team actively engaged with attendees, sharing success stories of how optimized mobile stores have driven significant growth for Shopify businesses. These stories highlighted the power of:</p>
                                            <ul data-sourcepos="19:1-22:0">
                                                <li data-sourcepos="19:1-19:169"><strong>Increased mobile traffic and conversions:</strong>&nbsp;By optimizing mobile stores, Pagarba helps businesses reach more potential customers and convert them into loyal patrons.</li>
                                                <li data-sourcepos="20:1-20:171"><strong>Enhanced customer satisfaction:</strong>&nbsp;Streamlined mobile experiences result in increased customer satisfaction and loyalty, leading to repeat purchases and brand advocacy.</li>
                                                <li data-sourcepos="21:1-22:0"><strong>Improved brand image:</strong>&nbsp;A well-designed mobile store reinforces a brand's image as innovative and customer-centric, attracting new customers and building trust.</li>
                                            </ul>
                                            <p data-sourcepos="23:1-23:68"><strong>Collaboration and Innovation for a Thriving E-commerce Landscape</strong></p>
                                            <p data-sourcepos="25:1-25:241">Pagarba utilized the summit to forge valuable partnerships and network with industry leaders. Through discussions and collaborations, Pagarba actively contributes to developing innovative solutions for the ever-evolving e-commerce landscape.</p>
                                            <p data-sourcepos="27:1-27:69"><strong>Looking Ahead: A Future Focused on Captivating Mobile Experiences</strong></p>
                                            <p data-sourcepos="29:1-29:327">The RD Summit 2023 proved instrumental for Pagarba to connect with businesses, showcase its expertise, and contribute to the growth of the Brazilian e-commerce ecosystem. Pagarba remains committed to continuous innovation, empowering businesses with the tools and expertise needed to create captivating mobile stores that sell.</p>
                                            <p data-sourcepos="31:1-31:46"><strong>Join Pagarba on the Road to Mobile Success</strong></p>
                                            <p data-sourcepos="33:1-33:236">Whether you missed the RD Summit 2023 or are looking to build a captivating mobile store, Pagarba is here to help. Connect with us today to discuss how we can optimize your Shopify store and unlock the full potential of mobile commerce.</p>
                                            <p data-sourcepos="35:1-35:81"><strong>#RDsummit #Pagarba #Shopify #eCommerce #Mobile #MobileFirst #CaptivateAndSell</strong></p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copyright-section">
                                    <p>2023 Copyright &copy; powered by Pagarba</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDetails;
